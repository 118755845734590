/* eslint-disable */
import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import UserLeft from '../PersonUserLeft.vue'
import page from '../../../components/page.vue'
import NoData from '../../../components/NoData.vue'
import CheckInService from '../../../api/CheckInService.js'


export default{
	name:'UserMessageList',
	data(){
		return{
			pageIndex:1,
			pageSize:10,
			pageCount:0,
			messageList:[],
		}
	},
	methods:{
		GetMessageList(){ //获取消息列表
			var that = this
			CheckInService.GetMessageList(that.pageIndex,that.pageSize,that.$UserID).then(res=>{
				if(res.Tag == 1){
					that.messageList = res.Result
					that.pageCount = res.TotalCount
					setTimeout(function(){
						$(".xxgl_box_c ul li").click(function(){
							$(".xxgl_box_c ul li>p").addClass('white_s')
							$(".xxgl_box_c ul li>p").eq($(this).index()).removeClass('white_s')
						})
					},2000)
				}
			})
		},
		pageChange(page){
			this.pageIndex = page
			this.GetMessageList()
			window.scrollTo(0,0)
		},
		ReadMessage(value){ //阅读消息
			var that = this
			CheckInService.UpdateReadStatus(value.ID).then(res=>{
				if(res.Tag == 1){
					value.RecipientState = 1
				}
			})
		},
		DeleteMessage(id){ //删除消息
			var that = this
			that.$alert('确定删除该消息吗', '提示', {
				confirmButtonText: '确定',
				callback: action => {
					if (action == "confirm") {
						CheckInService.DeleteMessage(id).then(res=>{
							if(res.Tag == 1){
								that.GetMessageList()
							}
						})
					}
				}
			});
			
		}
	},
	created(){
		this.GetMessageList()
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'UserLeft':UserLeft,
		'page':page,
		'NoData':NoData
	}
}