<template>
	<div class="cont_left_box">
		<div class="listtitle_box">
			<i></i>
			<img src="../../images/grzx_ico.png">
		</div>
		<div class="cont_left_box_c">
			<div class="cont_left_li">
				<router-link :to="{path:'/UserPurchaseList'}">
					<img src="../../images/left_ico1.png" />
					<h2>我的已购</h2>
				</router-link>
			</div>
			<div class="cont_left_li">
				<router-link :to="{path:'/UserCollection'}">
					<img src="../../images/left_ico2.png" />
					<h2>我的收藏</h2>
				</router-link>
			</div>
			<div class="cont_left_li">
				<router-link :to="{path:'UserManuscriptList'}">
					<img src="../../images/left_ico3.png" />
					<h2>我的投稿</h2>
				</router-link>
			</div>
			<div class="cont_left_li">
				<router-link :to="{path:'/UserMessageList'}">
					<img src="../../images/left_ico4.png" />
					<h2>系统消息</h2>
				</router-link>
			</div>
			<div class="cont_left_li">
				<a href="javascript:void(0)">
					<img src="../../images/left_ico5.png" />
					<h2>账号管理</h2>
				</a>
				<div class="cont_left_lic" style="display: none;">
					<ul>
						<li :class="childIndex == 0?'cur':''">
							<a href="javascript:void(0)" @click="toUserCenter()">个人资料</a>
						</li>
						<li :class="childIndex == 1?'cur':''">
							<a href="javascript:void(0)" @click="toUserSafe()">安全设置</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */
	export default {
		props: ['leftIndex', 'childIndex'],
		methods: {
			toUserCenter() { //个人资料
				if (this.$userType == 0) { //个人用户
					this.$router.push({
						path: 'PerUserInfo'
					})
				} else {
					this.$router.push({
						path: '/OrgUserInfo'
					})
				}
			},
			toUserSafe() { //安全中心
				this.$router.push({
					path: 'PerUserSafe'
				})
			}
		},
		mounted() {
			$(".cont_left_box_c .cont_left_li").removeClass("cur");
			$(".cont_left_box_c .cont_left_li").eq(this.leftIndex).addClass("cur")
			$(".cont_left_li>a").click(function(){
				if($(this).siblings('.cont_left_lic').css('display')=="block"){
					$(this).siblings(".cont_left_lic").slideUp();
				}else{
					$(".cont_left_lic").slideUp()
					$(".cont_left_li").removeClass('cur');
					$(this).siblings(".cont_left_lic").slideDown();
					$(this).parent().addClass("cur")
				}
			})
		}
	}
</script>

<style>
</style>
